import React from 'react'
import classes from './FullWidthSection.module.scss'
import Markdown from 'markdown-to-jsx';
import Typography from '../../../../../remitbee/components/typography/Typography';
import theme from '../../../../../remitbee/theme/Theme';
import { useMediaQuery } from '@mui/material';

interface TableProps {
  columns: Array<any>;
  data: Array<any>;
  columnsBackground?: string;
  isEvenColor?: boolean;
  variant?: string;
  splitTableInMobile?: boolean;
  thTextSize?: string;
}

const Table: React.FC<TableProps> = ({
  splitTableInMobile = false,
  columns,
  data,
  columnsBackground,
  isEvenColor = false,
  thTextSize
}) => {
  const columnKeys = Object.keys(columns[0]);
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);


  const tableToBeSplited = splitTableInMobile
      ? isMobile
          ? true
          : false
      : false;
      

  return (
      <div className={classes['rb-table-container']} >
          {!tableToBeSplited ? (
              <table className={classes['rb-custom-table']}>
                  <thead>
                      <tr>
                          {columnKeys?.map((key, index) => (
                              <th
                                  key={index}
                                  style={{
                                      backgroundColor:
                                          columnsBackground &&
                                          columnsBackground,
                                  }}
                              >
                                  {typeof columns[0][key] === 'string' ? (
                                      <Typography
                                          variant={thTextSize || isMobile ?  'textMd' :"textXl"}
                                          weight="semibold"
                                          color={
                                              columnsBackground
                                                  ? theme.palette.background
                                                        .white
                                                  : theme.palette.primary.navy
                                          }
                                          className={classes['rb-table-th-text']}
                                      >
                                          {
                                              <Markdown
                                                  children={columns[0][key]}
                                              />
                                          }
                                      </Typography>
                                  ) : (
                                      columns[0][key]
                                  )}
                              </th>
                          ))}
                      </tr>
                  </thead>
                  <tbody>
                      {data?.map((row, rowIndex) => (
                          <tr
                              key={`row-${rowIndex}`}
                              style={{
                                  backgroundColor:
                                      rowIndex % 2 === 1 && isEvenColor
                                          ? theme.palette.background.lightGray
                                          : '',
                              }}
                          >
                              {columnKeys?.map((key, colIndex) => (
                                  <td key={`col-${colIndex}`}>
                                      <Typography
                                          variant={isMobile ? 'textSm' : 'textMd'}
                                          weight="medium"
                                          className={classes['rb-table-text']}
                                      >
                                          {typeof row[key] === 'string' ? (
                                              <Markdown children={row[key]} />
                                          ) : (
                                              row[key]
                                          )}
                                      </Typography>
                                  </td>
                              ))}
                          </tr>
                      ))}
                  </tbody>
              </table>
          ) : (
              <table className={classes['rb-custom-table']} >
                  {columnKeys.map((key, index) => {
                      if (!columns[0][key]) return null;
                      return (
                          <>
                              <thead
                                  className={classes['rb-split-table-custom']}
                                  key={`head-${index}`}
                              >
                                  <tr>
                                      <th
                                          colSpan={columnKeys.length}
                                          style={{
                                              backgroundColor:
                                                  columnsBackground &&
                                                  columnsBackground,
                                          }}
                                      >
                                          {typeof columns[0][key] ===
                                          'string' ? (
                                              <Typography
                                              variant={thTextSize || isMobile ?  'textMd' :"textXl"}
                                                  weight="semibold"
                                                  className={classes['rb-table-th-text']}
                                                  color={
                                                      columnsBackground
                                                          ? theme.palette
                                                                .background
                                                                .white
                                                          : theme.palette
                                                                .primary.navy
                                                  }
                                              >
                                                  {
                                                      <Markdown
                                                          children={
                                                              columns[0][key]
                                                          }
                                                      />
                                                  }
                                              </Typography>
                                          ) : (
                                              columns[0][key]
                                          )}
                                      </th>
                                  </tr>
                              </thead>
                              <tbody>
                                  {data?.map((row, rowIndex) => {
                                      return (
                                          <tr
                                              key={`row-1-${rowIndex}`}
                                              style={{
                                                  backgroundColor:
                                                      rowIndex % 2 === 1 &&
                                                      isEvenColor
                                                          ? theme.palette
                                                                .background
                                                                .lightGray
                                                          : '',
                                              }}
                                          >
                                              <td>
                                                  <Typography
                                                      variant={isMobile ? 'textSm' : 'textMd'}
                                                      weight="medium"
                                                      align="left"
                                                      className={classes['rb-table-text']}
                                                  >
                                                      {typeof row[key] ===
                                                      'string' ? (
                                                          <Markdown
                                                              children={
                                                                  row['col1']
                                                              }
                                                          />
                                                      ) : (
                                                          row['col1']
                                                      )}
                                                  </Typography>
                                              </td>
                                              <td>
                                                  <Typography
                                                      variant={isMobile ? 'textSm' : 'textMd'}
                                                      weight="medium"
                                                      align="right"
                                                      className={classes['rb-table-text']}
                                                  >
                                                      {typeof row[key] ===
                                                      'string' ? (
                                                          <Markdown
                                                              children={
                                                                  row[key]
                                                              }
                                                          />
                                                      ) : (
                                                          row[key]
                                                      )}
                                                  </Typography>
                                              </td>
                                          </tr>
                                      );
                                  })}
                              </tbody>
                          </>
                      );
                  })}
              </table>
          )}
      </div>
  );
};

export default Table;