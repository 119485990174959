import React, { useEffect, useState } from 'react'
import { fetchCountriesFromRates } from '../../../../../redux/system/actions';
import { useSelector } from 'react-redux';
import classes from './FullWidthSection.module.scss'
import Typography from '../../../../../remitbee/components/typography/Typography';
import theme from '../../../../../remitbee/theme/Theme';

const CANADA_CURRENCY_CODE = 'CAD';
const CANADA_CURRENCY_NAME = 'Canadian dollar';
const sendingValues = [1, 5, 10, 20, 50, 100, 150, 200, 250, 500, 1000, 1200, 1500, 2000, 2500, 3000];


const SimpleConversionTable = ({ currency_code, tableWidth = null, storyBookMockData = [] }) => {
  const { countries } = storyBookMockData.length > 0 ? storyBookMockData : useSelector((state) => state.system);
  const [tranferRates, setTransferRates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currencyCodeLabel, setCurrencyCodeLabel] = useState(null);
  const [rate, setRate] = useState(1);
  const [currencyCode, setCurrencyCode] = useState(currency_code);
  const [currencyName, setCurrencyName] = useState(null);

  useEffect(() => {
    setLoading(true);
    const getCountries = async () => {
      if (countries) {
        setTransferRates(countries)
        return
      }
      if (storyBookMockData.length > 0) {
        setTransferRates(storyBookMockData)
        return
      }
      fetchCountriesFromRates();
    }

    getCountries();
    setLoading(false);
  }, [countries]);

  const updateCurrencyInfo = (code) => {
    const selectedCountry = tranferRates.filter((country) => {
      if (!isNaN(Number(code)) && country.country_to_id === +code) {
        setCurrencyCodeLabel(country.currency_code)
        return true;
      }
      if (country.currency_code === code) {
        setCurrencyCodeLabel(code)
        return true
      }
      return false;
    });

    if (selectedCountry && selectedCountry.length) {
      const { rate, country_to, currency_name } = selectedCountry[0];
      setRate(rate);
      setCurrencyName(currency_name)
    }
  }

  useEffect(() => {
    if (tranferRates.length > 0 && currencyCode) updateCurrencyInfo(currencyCode)
    if (storyBookMockData.length > 0 && currencyCode) updateCurrencyInfo(currencyCode)
  }, [currencyCode, tranferRates])

  const sendingValueRows = sendingValues.map(value => ({
    sending: `${value} ${CANADA_CURRENCY_CODE}`,
    receiving: `${Number(rate * value).toFixed(2)} ${currencyCodeLabel}`
  }));

  return (
    <div className={classes['rb-exchange-rate-table']} style={{ width: tableWidth && tableWidth }}>
      <table>
        <thead>
          <tr>
            <th colSpan={2}><Typography variant='textXl' weight='semibold' color={theme.palette.primary.navy}>{`CAD to ${currencyCode} exchange rate`}</Typography></th>
          </tr>
        </thead>
        <tbody>
          {sendingValueRows.map((rate, index) => (
            <tr key={index}>
              <td><Typography weight='semibold' color={theme.palette.primary.blue} variant='textMd' className={classes['rb-table-text']}>{rate.sending}</Typography></td>
              <td align='right'><Typography variant='textMd' className={classes['rb-table-text']} >{rate.receiving} </Typography></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default SimpleConversionTable