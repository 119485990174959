import Slider from '@mui/material/Slider';
import { withStyles } from '@mui/styles';

export const CustomSlider = withStyles({
    root: {
        color: '#1160F7 !important',
        padding: '10px 0',
        height: '8px !important',
    },
    thumb: {
        height: '24px !important',
        width: '24px !important',
        backgroundColor: "#fff !important",
        border: '3px solid #1160F7 !important',
        marginLeft: -9,
        '& .bar': {
            height: 9,
            width: 1,
            backgroundColor: 'currentColor',
            marginLeft: 1,
            marginRight: 1,
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: '8px !important',
        borderRadius: '5px',
    },
    rail: {
        color: '#E8E8E8 !important',
        opacity: 1,
        borderRadius: '5px',
        height: 8,
    },
    mark: {
        width: '9px',
        height: '9px',
        marginTop: '-2px',
        backgroundColor: '#bfbfbf',
        borderRadius: '50%',
        opacity: 0,
    },
    markActive: {
        opacity: 0,
        marginTop: '-3px',
        backgroundColor: 'white',
        border: '1px solid #37517A',
    },
})(Slider);
